import { useState } from "react";
import { IconContext } from "react-icons";
import { AiFillEye, AiFillInfoCircle } from "react-icons/ai";
import { BsIncognito } from "react-icons/bs";
import controller from "../../../assets/svgs/controller.svg";
import AutorunMoving from "../../robots/AutorunMoving";
import TeleopCompleted from "../../robots/TeleopCompleted";
import RobotActions from "../../robots/RobotActions";
import AccessTransferModal from "../modals/AccessTransferModal";
import DuplicateTabModal from "../modals/DuplicateTabModal";
import AcceptTransferModal from "../modals/AcceptTransferModal";
import ClearCommandsModal from "../modals/ClearCommandsModal";

const SidebarTeleop = ({
  robotDetails,
  robotId,
  unlockController,
  sidebar,
  showMinimizeBtn,
  lock,
  teleopBorder,
  liveCount,
  viewCount,
  streamLive,
  controllerMessage,
  controllerData,
  openDuplicateTabModal,
  setOpenDuplicateTabModal,
  signallingChannel,
  peerId,
  target,
  openAcceptTransferModal,
  setOpenAcceptTransferModal,
  setConLock,
  accessTimer,
  setAccessTimer,
}) => {
  const [unlockChecked, setUnlockChecked] = useState(false);
  const [openAccessTransferModal, setOpenAccessTransferModal] = useState(false);
  const [openClearCommandsModal, setOpenClearCommandsModal] = useState(false);

  const handleUnlockSwitchClick = (streamLive) => {
    if (streamLive && controllerData.gotAccess) {
      const newValue = !unlockChecked;
      setUnlockChecked(!unlockChecked);
      unlockController(newValue);
      lock(newValue);
    }
  };

  const handleRequestAccess = () => {
    setOpenAccessTransferModal(true);
  };

  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <aside
        className={`teleop__details p-0 relative gap-[7px] ${
          sidebar ? "opacity-100" : "opacity-0"
        }`}
      >
        {!!Number(process.env.REACT_APP_OTTONOMY) && (
          <>
            {parseInt(robotDetails[robotId]?.robotBattery).toFixed(0) <= 20 && (
              <span
                className={`absolute ${
                  !!Number(process.env.REACT_APP_STREAM_SELF)
                    ? "-top-5"
                    : "top-5"
                } -right-6 p-1.5 bg-[#fb6262] rounded-full z-[1]`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35.006"
                  height="34.986"
                  viewBox="0 0 35.006 34.986"
                >
                  <g
                    id="Group_152"
                    data-name="Group 152"
                    transform="translate(-733.997 -574.007)"
                  >
                    <g
                      id="noun-battery-149442"
                      transform="translate(723.279 563.493)"
                    >
                      <path
                        id="Path_107"
                        data-name="Path 107"
                        d="M16.654,16.439a16.35,16.35,0,0,1,22.358-.722H36.527V16.6h4.05v-4.05h-.884v2.583a17.243,17.243,0,0,0-27,20.359l.8-.384A16.376,16.376,0,0,1,16.654,16.439Zm27.123,4.123-.8.382A16.357,16.357,0,0,1,17.468,40.33h2.481v-.884H15.9V43.5h.884V40.907A17.243,17.243,0,0,0,43.777,20.562Z"
                        transform="translate(0)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="0.5"
                      />
                    </g>
                    <g
                      id="Rectangle_164"
                      data-name="Rectangle 164"
                      transform="translate(739 585)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="1"
                    >
                      <rect width="23" height="13" rx="4" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="22"
                        height="12"
                        rx="3.5"
                        fill="none"
                      />
                    </g>
                    <rect
                      id="Rectangle_165"
                      data-name="Rectangle 165"
                      width="3"
                      height="4"
                      rx="1.5"
                      transform="translate(761 590)"
                      fill="#fff"
                    />
                    <rect
                      id="Rectangle_166"
                      data-name="Rectangle 166"
                      width="6"
                      height="7"
                      transform="translate(742 588)"
                      fill="#fff"
                    />
                  </g>
                </svg>

                <span
                  className={`absolute ${
                    !!Number(process.env.REACT_APP_STREAM_SELF)
                      ? "-top-1"
                      : "-bottom-1"
                  } right-8 z-[1] flex justify-center pb-0.5 pl-1 pr-0.5 w-max gap-0.5 items-center rounded-full bg-[#fb6262]`}
                >
                  {parseInt(robotDetails[robotId]?.robotBattery).toFixed(0) <
                  0 ? (
                    <p className="text-[10px] text-white font-lilita italic font-light">
                      Battery Error
                    </p>
                  ) : (
                    <p className="text-[10px] text-white font-lilita italic font-light">
                      Battery Swap Required
                    </p>
                  )}
                  <AiFillInfoCircle className="text-white h-[13px] w-[13px]" />
                </span>
              </span>
            )}
            <div
              className={`robot__teleop__details__container p-[5px] rounded-[7px] bg-[#292F40]
           ${teleopBorder}`}
            >
              <div className="robot__teleop__details__header">
                <div className="robot__teleop">
                  <AutorunMoving
                    robotId={robotId}
                    robotData={robotDetails[robotId]}
                  />
                </div>
                <TeleopCompleted
                  robotId={robotId}
                  robotData={robotDetails[robotId]}
                />
              </div>
              <RobotActions
                robotId={robotId}
                robotData={robotDetails[robotId]}
                setOpenClearCommandsModal={setOpenClearCommandsModal}
              />
              <span className="text-[10px] text-white pt-1 pl-0.5">
                {" "}
                {controllerMessage}
              </span>
            </div>
          </>
        )}

        {!!Number(process.env.REACT_APP_STREAM_SELF) && (
          <div className="flex flex-col">
            <div className="users__info__panel p-[3px]">
              <div className="users__info">
                <div className="game__controller__status">
                  <img src={controller} alt="controller svg" />
                  <span className="controller__status">
                    {unlockChecked ? "ON" : "OFF"}
                  </span>
                </div>

                {!!Number(process.env.REACT_APP_MULTI_STREAM) && (
                  <div className="flex flex-col gap-1">
                    <div className="pt-2 robot__actions">
                      {!!Number(process.env.REACT_APP_MULTI_STREAM) && (
                        <span className="teleop__watching">
                          {" "}
                          <AiFillEye className="watching__icon" />
                          <strong>{viewCount}</strong> Watching
                        </span>
                      )}
                    </div>
                    <div className="pb-2 circle__icons">
                      {Array(viewCount > 3 ? 3 : viewCount)
                        .fill("Anonymous")
                        .map((user, index) => {
                          return (
                            <div
                              className="circle__icon"
                              key={"circle__icon__" + index}
                            >
                              <div
                                className={`flex items-center justify-center main-circle hover-text`}
                                style={{
                                  background: !user ? "#71cf62" : "#bec4de",
                                }}
                              >
                                <BsIncognito className="p-[2px] !text-[#1C1E2B]" />
                                <span
                                  className={`tooltip ${teleopBorder}`}
                                  style={{
                                    borderWidth: "2px",
                                  }}
                                  id="bottom"
                                >
                                  {user}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      {!!Number(process.env.REACT_APP_MULTI_STREAM) &&
                        viewCount > 3 && (
                          <div className="circle__icon">
                            <div
                              className={`flex items-center text-[11px] justify-center main-circle hover-text`}
                              style={{
                                background: "#bec4de",
                              }}
                            >
                              +{viewCount - 3}
                              <span
                                className={`tooltip ${teleopBorder}`}
                                style={{
                                  borderWidth: "2px",
                                }}
                                id="bottom"
                              >
                                + {viewCount - 3} others
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </div>
              {controllerData &&
              Object.keys(controllerData).length > 0 &&
              !controllerData.gotAccess ? (
                <div
                  onClick={() => handleRequestAccess()}
                  className="request__access__btn text-[#292F40]"
                >
                  Take Control
                </div>
              ) : (
                <div
                  onClick={() =>
                    handleUnlockSwitchClick(
                      streamLive,
                      controllerData.gotAccess
                    )
                  }
                  className="unlock__btn"
                  style={{
                    color:
                      streamLive && controllerData.gotAccess
                        ? "#292F40"
                        : "gray",
                    cursor:
                      streamLive && controllerData.gotAccess
                        ? "pointer"
                        : "not-allowed",
                  }}
                >
                  {unlockChecked ? "Lock Joystick" : "Unlock Joystick"}
                </div>
              )}
            </div>
          </div>
        )}
      </aside>
      <AccessTransferModal
        openAccessTransferModal={openAccessTransferModal}
        setOpenAccessTransferModal={setOpenAccessTransferModal}
        robotId={robotId}
        controllerData={controllerData}
        signallingChannel={signallingChannel}
        peerId={peerId}
        target={target}
      />
      <AcceptTransferModal
        openAcceptTransferModal={openAcceptTransferModal}
        setOpenAcceptTransferModal={setOpenAcceptTransferModal}
        robotId={robotId}
        controllerData={controllerData}
        signallingChannel={signallingChannel}
        peerId={peerId}
        target={target}
        setUnlockChecked={setUnlockChecked}
        unlockController={unlockController}
        lock={lock}
        accessTimer={accessTimer}
        setAccessTimer={setAccessTimer}
      />
      <DuplicateTabModal
        openDuplicateTabModal={openDuplicateTabModal}
        setOpenDuplicateTabModal={setOpenDuplicateTabModal}
        robotId={robotId}
      />
      <ClearCommandsModal
        openClearCommandsModal={openClearCommandsModal}
        setOpenClearCommandsModal={setOpenClearCommandsModal}
        robotId={robotId}
      />
    </IconContext.Provider>
  );
};

export default SidebarTeleop;
