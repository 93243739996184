import { Link } from "react-router-dom";
import LogoutModal from "../modals/LogoutModal";
import "../../../App.css";

function Header({ useremail, firstName, lastName, userRole }) {
  return (
    <div className="py-3 nav-main">
      <div className="nav-items">
        <div className="nav-left">
          <div className="nav-logo">
            <Link to={"/"}>
              <img
                src="/assets/images/OttonomyLogo.png"
                className="HeaderMain-logo-image"
                alt="main__logo"
              />
            </Link>
          </div>
        </div>

        <div className="items-center nav-right ">
          <div className="flex flex-col items-start justify-center">
            {firstName &&
              firstName !== "undefined" &&
              firstName !== "null" &&
              lastName &&
              lastName !== "undefined" &&
              lastName !== "null" &&
              useremail &&
              useremail !== "undefined" &&
              useremail !== "null" && (
                <span className="capitalize user-email">
                  {firstName && firstName + " " + lastName}
                  {!firstName && useremail}
                </span>
              )}
            {userRole && userRole !== "undefined" && (
              <span className="m-0 text-xs text-white capitalize opacity-70">
                {userRole}
              </span>
            )}
          </div>
          <LogoutModal header={true} />
        </div>
      </div>
    </div>
  );
}

export default Header;
