import { Fragment, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFleetListUser } from "../apis";
import IndividualRobot from "../components/robots/IndividualRobot";
import Header from "../components/layout/navbar/Header";
import TeleopAck from "../components/robots/TeleopAck";

function SelectFleet() {
  const [fleetList, setFleetList] = useState();
  const [showHeading, setShowHeading] = useState(false);
  const [headingFlag, setHeadingFlag] = useState(false);

  const navigate = useNavigate();

  const robotMsgData = useSelector((state) => state.robotMessageReducer);
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  const { user } = useSelector((state) => state.userAuthReducer);

  const token = sessionStorage.getItem("token");
  const userEmail = user?.username;
  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const userRole = user?.role;

  const getRobotStatus = (robot) => {
    switch (robot?.robotStatus) {
      case "AVAILABLE":
        return (
          <>
            <span className="m-0 text-left p-color-green">Available </span>{" "}
            <span
              style={{
                color: "#f7ca28",
                fontSize: "12px",
                textTransform: "lowercase",
              }}
            >
              {/* ({robot.robotRunningState.split("_").join(" ")}) */}
            </span>
          </>
        );
      case "UNAVAILABLE":
        return (
          <>
            <span className="m-0 text-left p-color-red"> Unavailable </span>{" "}
            <span
              style={{
                color: "#f7ca28",
                fontSize: "12px",
                textTransform: "lowercase",
              }}
            >
              {/* ({robot.robotRunningState.split("_").join(" ")}) */}
            </span>
          </>
        );
      case "CHARGING":
        return (
          <>
            <span className="m-0 text-left p-color-green">Charging </span>
            <span
              style={{
                color: "#f7ca28",
                fontSize: "12px",
                textTransform: "lowercase",
              }}
            >
              {/* ({robot.robotRunningState.split("_").join(" ")}) */}
            </span>
          </>
        );
      case "ERROR":
        return <p className="m-0 text-left p-color-red"> Error</p>;
      default:
        return (
          <p className="m-0 text-left p-color-red"> {robot?.robotStatus}</p>
        );
    }
  };

  const getRunningStatusColor = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "Dashboard_page_individual_robot_robotstatus_div AutoSystem_fault_error_p";
      case "ERROR":
        return "Dashboard_page_individual_robot_robotstatus_div AutoSystem_fault_error_p";
      case "AUTO_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div AutoObstacle_teleop_obstacle_p";
      case "AUTO_RUN_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "AUTO_RUN_MOVING":
        return "Dashboard_page_individual_robot_robotstatus_div AutoRun_moving_p";
      case "AUTO_RUN":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "TELE_OP_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div AutoObstacle_teleop_obstacle_p";
      case "TELE_OP_MOVING":
        return "Dashboard_page_individual_robot_robotstatus_div teleopMoving_bordertop_p";
      case "TELE_OP":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "PAYLOAD_STATE":
        return "Dashboard_page_individual_robot_robotstatus_div payloadState_bordertop_p";
      case "MANUAL":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      default:
        return "Dashboard_page_individual_robot_robotstatus_div defaultBorderTop_P";
    }
  };

  const getRobotDot = (status) => {
    switch (status) {
      case "UNAVAILABLE":
        return <span className="red-dot"> </span>;
      case "AVAILABLE":
        return <span className="green-dot"> </span>;
      case "ERROR":
        return <span className="yellow-dot"> </span>;
      default:
        return <span className="red-dot"> </span>;
    }
  };

  const handleRobotData = (robotdata) => {
    sessionStorage.setItem("fleetId", robotdata?.fleetId);
    navigate(`/dashboard/${robotdata?.robotId}`, {
      state: { name: robotdata },
    });
  };

  const calculateFleetLength = (fleetId) => {
    let count = 0;
    Object.keys(robotDetails).map((id) => {
      if (robotDetails[id].fleetId === fleetId) {
        count++;
      }
      return null;
    });
    return count;
  };

  useEffect(() => {
    if (
      token === "" ||
      token === "null" ||
      token === null ||
      token === undefined
    ) {
      navigate("/");
    }

    if (userEmail && token) {
      // if (userRole.toLowerCase() === "admin") {
      //   getFleetListAdmin(userEmail, token)
      //     .then((res) => {
      //       setFleetList(res?.data?.fleet);
      //       // navigate(`/selectfleet`, { state: { name: res?.data } });
      //     })
      //     .catch((err) => {
      //       console.error(err);
      //     });
      // } else {
      getFleetListUser(userEmail, token)
        .then((res) => {
          res?.data?.fleet.length > 0
            ? setFleetList(res?.data?.fleet)
            : navigate("/dashboard");
        })
        .catch((err) => {
          console.error(err);
        });
      // }
    }

    setHeadingFlag(false);
    Object.keys(robotDetails)?.map((data) => {
      if (
        robotDetails[data].teleop === "YES" &&
        robotDetails[data].robotRunningState !== "TELE_OP_MOVING" &&
        robotDetails[data].robotRunningState !== "TELE_OP_OBSTACLE" &&
        robotDetails[data].robotRunningState !== "TELE_OP"
      ) {
        setHeadingFlag(true);
      }
      if (setHeadingFlag) {
        setShowHeading(true);
      }
      return null;
    });
  }, [
    robotMsgData,
    showHeading,
    robotDetails,
    token,
    userRole,
    userEmail,
    navigate,
    user,
  ]);

  return (
    <>
      <Header
        useremail={userEmail}
        firstName={firstName}
        lastName={lastName}
        userRole={userRole}
      />
      <div className="flex flex-col flex-wrap gap-4 container-fluid SelectFleetPage-wrapper-Main">
        <div className={`${headingFlag && "fleet__card w-full py-3"}`}>
          {headingFlag && (
            <>
              <span className="fleet__card_name">Robot Message</span>
              <audio autoPlay={true} loop>
                <source
                  src="/static/media/alert.aa9b8554cbeaf7968e81.wav"
                  type="audio/wav"
                ></source>
              </audio>
            </>
          )}

          <div className="gap-3 SelectFleet-page-Error-box-wrapper">
            {Object.keys(robotDetails).map(
              (data, index) =>
                robotDetails[data].teleop === "YES" &&
                robotDetails[data].robotRunningState !== "TELE_OP_MOVING" &&
                robotDetails[data].robotRunningState !== "TELE_OP_OBSTACLE" &&
                robotDetails[data].robotRunningState !== "TELE_OP" && (
                  <TeleopAck
                    key={"robot__details_" + index}
                    uniqueKey={"robot__details_" + index}
                    robotDetails={robotDetails}
                    data={data}
                    handleRobotData={handleRobotData}
                    getRobotDot={getRobotDot}
                    getRobotStatus={getRobotStatus}
                    getRunningStatusColor={getRunningStatusColor}
                  />
                )
            )}
          </div>
        </div>

        <div className="fleet__details__container">
          {fleetList &&
            fleetList?.map((fleet, index) => {
              return (
                <Fragment key={"fleet__no_" + index}>
                  <div className="fleet__card">
                    <span className="fleet__card_name">
                      {fleet?.fleetName?.split("_")?.join(" ")}
                    </span>
                    <IndividualRobot
                      data={fleet}
                      latitude={fleet?.latitude}
                      longitude={fleet?.longitude}
                      fleetLength={calculateFleetLength(fleet.fleetId)}
                    />
                  </div>
                </Fragment>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default memo(SelectFleet);
